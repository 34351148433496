/* Assuming the styles.css file is in the same directory as assets folder */
body {
  background-image: url("/public/bg33.png");
  background-color: #101010;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  /* color: #b70000; */
  overflow-x: hidden;
  
}

.navbar {
  
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
}
/* .navbar button {
  
    margin-left: 10px;
    background-color: #ea0000;
    color: #fff;
    border: none;
    padding: 10px 20px;
  } */

.button1 {
  /* Unique styles for Button 1 */
  margin-left: 10px;
  margin-top: 35px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  width: 90px;
  border-radius: 30px;
  padding: 10px 20px;
}

.button2 {
  /* Unique styles for Button 2 */
  margin-left: 15px;
  margin-right: 7%;
  margin-top: 35px;
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  width: 90px;
  border-radius: 30px;
  padding: 10px 20px; /* White border */
}
.button3 {
  /* Unique styles for Button 2 */

  margin-right: 7%;
  margin-top: 35px;
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  width: 166px;
  height: 55px;
  border-radius: 77px;
  padding: 10px 20px; /* White border */
}

.text-container {
  margin-top: 7%; /* Adjust spacing below the navbar */
  position: relative;
  margin-left: 7%;
  text-align: left; /* Align text to the left */
  align-items: flex-start;
  display: flex;

  flex-direction: row;

  /* Other styles for container appearance */
}



.text-container .MuiTypography-h5 span:first-child {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.additional-sentence {
  display: none;
  font-size: 16px;
  color: #ffffff;
  margin-top: 10px; /* Adjust spacing from previous content */
  /* Adjust alignment as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-image {
  flex-shrink: 0; 
  margin-left: 10%;
  
  margin-top: -20px;
}

.text-content {
  display: flex;
  flex-direction: column;
  padding-top: 132px; /* Arrange text elements vertically */
}
.image-1{
  margin-top: 35px;
  margin-left: 106px;
  height: 413px;
  width: 753px;
}
.image-12 {
  border: 1px solid rgb(255 255 255 / 33%); /* Adjust the alpha value as needed */
  padding: 13px;
  margin-left: 37px;
  width: 343px;
  margin-top: 80px;
}
.image-13 {
  border: 1px solid rgb(255 255 255 / 33%); /* Adjust the alpha value as needed */
    padding: 13px;
    margin-left: 37px;
    width: 343px;
    margin-top: 80px;
}

.creasoft-wrap {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.line_wrap {
  top: 0px;
  left: 50%;
  bottom: 0px;
  width: 80%;
  z-index: -1;
  display: block;
  position: absolute;
  transform: translateX(-50%);
  border-left: 1px solid rgba(64, 0, 255, 0.06);
}

@media (max-width: 767px) {
  .line_wrap {
    border-left: 1px solid #ffffff02;
  }
}

.line_wrap.layout2 {
  border-left: 1px solid rgba(246, 246, 246, 0.02);
}

.line_wrap.layout2 .line_item {
  border-right: 1px solid rgba(255, 0, 0, 0.02);
}

.line_wrap:before {
  width: 1px;
  left: -1px;
  content: "";
  height: 100px;
  position: absolute;
  animation: scroll1 15s ease-out infinite;
  background-image: linear-gradient(0deg, #ffffffc4, transparent);
}

.line_item {
  width: 20%;
  float: left;
  height: 100%;
  position: relative;
  display: inline-block;
  border-right: 1px solid rgba(23, 22, 26, 0.06);
}

@media (max-width: 767px) {
  .line_item {
    border-right: 1px solid #ffffff06;
  }
}

.line_item:before {
  width: 1px;
  right: -1px;
  content: "";
  height: 100px;
  position: absolute;
}

.line_item:nth-child(odd):before {
  animation: scroll2 15s ease-out infinite;
  background-image: linear-gradient(0deg, transparent, #ffffffc4);
}

.line_item:nth-child(even):before {
  animation: scroll1 15s ease-out infinite;
  background-image: linear-gradient(0deg, #ffffffc4, transparent);
}

@keyframes scroll1 {
  0% {
    top: 0px;
    opacity: 1;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 100%;
    opacity: 0.5;
  }
}

@keyframes scroll2 {
  0% {
    opacity: 1;
    bottom: 0px;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 100%;
    opacity: 0.5;
  }
}


/* {mobile} */

@media (max-width: 768px) {
  .text-container {
    margin-top: 10%; 
    position: relative;
    margin-left: 7%;
    text-align: left; /* Align text to the left */
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }
  .image-1{
    margin-top: 8px;
    margin-left: -9px;
    height: 194px;
    width: 497px;
  }
  .text-container .MuiTypography-body1 {
    font-size: 42px; /* Adjust font size */
    color: #ffffff; /* Set text color */
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    /* Other styles for body text appearance */
  }
  
  .text-container .MuiTypography-h5 span:first-child {
    font-weight: bold;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .additional-sentence {
    font-size: 16px;
    color: #ffffff;
    display: none;
    margin-top: 10px; /* Adjust spacing from previous content */
    /* Adjust alignment as needed */
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  .text-image {
    flex-shrink: 0; /* Prevent image from shrinking */
    margin-left: 10%; /* Adjust spacing from text */
    /* margin-bottom: 70%; */
  }

  .text-content {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-left: 69px;
  }
}

/* {mobile} */

.text-content1 {
  margin-left: 27px;
  display: flex;
  flex-direction: column; /* Arrange text elements vertically */
  padding-top: 132px;
}
.text-content > * {
  margin-bottom: 15px; /* Adjust spacing between text elements */
}

.text-container1 {
  margin-top: 11%; /* Adjust spacing below the navbar */
  position: relative;
  margin-left: 60px;
  text-align: left; /* Align text to the left */
  align-items: flex-start;
  display: flex;

  flex-direction: row;

  /* Other styles for container appearance */
}

.text-image1 {
  flex-shrink: 0; /* Prevent image from shrinking */
  margin-left: 68px;
  /* margin-bottom: 70%; */
}
.text-image8{
  flex-shrink: 0; /* Prevent image from shrinking */
  margin-left: 0px;
  /* margin-bottom: 70%; */
}
.additional-sentence1 {
  font-size: 17px;
  color: #ffffff;
  padding-right: 94px;
  margin-top: 1px; /* Adjust spacing from previous content */
  word-spacing: -2px;
  /* Adjust alignment as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.lottie-container {
  display: block;
}

@media (max-width: 767px) {
  .lottie-container {
    display: none;
  }
}
.lottie-container1 {
 
  display: none;
}

/* Hide the container on screens with a width less than 768px (adjust as needed) */
@media (max-width: 767px) {
  .lottie-container1 {
    margin-top: 30px;
    display: block;
  }
}

.text-container1 .MuiTypography-h5 {
  font-size: 34px; /* Adjust font size */
  color: #ffffff; /* Set text color */

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for heading appearance */
}
.text-container1 .MuiTypography-body1 {
  font-size: 42px; /* Adjust font size */
  color: #ffffff; /* Set text color */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for body text appearance */
}

.text-container1 .MuiTypography-h5 span:first-child {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-content2 {
  display: flex;
  flex-direction: column; /* Arrange text elements vertically */
  padding-top: 70px;
  padding-left: 110px;
}

.text-container2 {
  margin-top: 5%; /* Adjust spacing below the navbar */
  margin-bottom: 175px;
  position: relative;
  margin-left: 60px;
  text-align: left; /* Align text to the left */
  align-items: flex-start;
  display: flex;

  flex-direction: row;

}

.text-image2 {
  height: 401px;
  flex-shrink: 0; /* Prevent image from shrinking */
  padding-right: 230px;
  padding-top: 43px;

  /* margin-bottom: 70%; */
}

.additional-sentence2 {
  font-size: 16px;
  color: #ffffff;
  padding-right: 200px;
  /* Adjust alignment as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-container2 .MuiTypography-h5 {
  font-size: 34px; /* Adjust font size */
  color: #000000; /* Set text color */

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for heading appearance */
}


.text-container2 .MuiTypography-h5 span:first-child {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-content3 {
  display: flex;
  flex-direction: column; /* Arrange text elements vertically */
  padding-top: 35px;
  padding-left: 70px;
}

.text-container3 {
  margin-top: 50px;
  position: relative;
  margin-left: 60px;
  text-align: left; /* Align text to the left */
  align-items: flex-start;
  display: flex;

  flex-direction: row;

  /* Other styles for container appearance */
}

.text-image3 {
  flex-shrink: 0; /* Prevent image from shrinking */
  padding-right: 290px;
  padding-left: 280px;
  padding-top: 85px;
  /* padding-top: 30px */

  /* margin-bottom: 70%; */
}

.additional-sentence3 {
  font-size: 16px;
  color: #ffffff;
  padding-right: 200px;
  /* Adjust alignment as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-container3 .MuiTypography-h5 {
  font-size: 34px; /* Adjust font size */
  color: #ffffff; /* Set text color */

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for heading appearance */
}
.text-container3 .MuiTypography-body1 {
  font-size: 42px; /* Adjust font size */
  color: #ffffff; /* Set text color */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for body text appearance */
}

.text-container3 .MuiTypography-h5 span:first-child {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-content4 {
  display: flex;
  flex-direction: column; /* Arrange text elements vertically */
  padding-top: 70px;
  padding-left: 153px;
}

.text-container4 {
  margin-top: 70px;
  position: relative;
  margin-left: 60px;
  text-align: left; /* Align text to the left */
  align-items: flex-start;
  display: flex;

  flex-direction: row;
}

.text-image4 {
  flex-shrink: 0; /* Prevent image from shrinking */
  padding-right: 250px;
  padding-top: 100px;

  /* margin-bottom: 70%; */
}

.additional-sentence4 {
  font-size: 16px;
  color: #ffffff;
  padding-right: 200px;
  /* Adjust alignment as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-container4 .MuiTypography-h5 {
  font-size: 34px; /* Adjust font size */
  color: #ffffff; /* Set text color */

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for heading appearance */
}
.text-container4 .MuiTypography-body1 {
  font-size: 42px; /* Adjust font size */
  color: #ffffff; /* Set text color */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for body text appearance */
}

.text-container4 .MuiTypography-h5 span:first-child {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-content5 {
  display: flex;
  flex-direction: column; /* Arrange text elements vertically */
  padding-top: 15px;
  padding-bottom: 35px;
  padding-left: 70px;
}

.text-container5 {
  margin-top: 70px;
  position: relative;
  margin-left: 60px;
  text-align: left; /* Align text to the left */
  align-items: flex-start;
  display: flex;

  flex-direction: row;

  /* Other styles for container appearance */
}

.text-image5 {
  flex-shrink: 0; /* Prevent image from shrinking */
  padding-right: 290px;
  padding-left: 230px;
  padding-top: 85px;
  /* padding-top: 30px */

  /* margin-bottom: 70%; */
}

.additional-sentence5 {
  font-size: 16px;
  color: #ffffff;
  padding-right: 200px;
  /* Adjust alignment as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-container5 .MuiTypography-h5 {
  font-size: 34px; /* Adjust font size */
  color: #ffffff; /* Set text color */

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for heading appearance */
}
.text-container5 .MuiTypography-body1 {
  font-size: 42px; /* Adjust font size */
  color: #ffffff; /* Set text color */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for body text appearance */
}

.text-container5 .MuiTypography-h5 span:first-child {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-content6 {
  padding-right: 80px;
  display: flex;
  flex-direction: column; /* Arrange text elements vertically */
  padding-top: 132px;
}
.text-content > * {
  margin-bottom: 15px; /* Adjust spacing between text elements */
}

.text-container6 {
  margin-top: 5%; /* Adjust spacing below the navbar */
  position: relative;
 margin-left: 57px;
  text-align: left; /* Align text to the left */
  align-items: flex-start;
  display: flex;

  flex-direction: row;

  /* Other styles for container appearance */
}

.text-image6 {
  flex-shrink: 0; /* Prevent image from shrinking */

  /* margin-bottom: 70%; */
}
.closed-label {
  text-align: center;
  padding: 10px;
  background-color: #000000; /* Set a background color */
  color: #fff; /* Set the text color */
  font-size: 18px;
  font-weight: bold;
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
  border-radius: 5px; /* Add rounded corners for a button-like appearance */
  transition: background-color 0.3s ease; /* Add a smooth transition for background color change */
}

.closed-label:hover {
  background-color: #ff0000; /* Change background color on hover for a feedback effect */
}

.additional-sentence6 {
  text-align: justify;
  font-size: 16px;
  color: #ffffff;
  padding-right: 0px;
  margin-top: 15px; /* Adjust spacing from previous content */
  /* Adjust alignment as needed */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text-container6 .MuiTypography-h5 {
  font-size: 34px; /* Adjust font size */
  color: #ffffff; /* Set text color */

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for heading appearance */
}
.text-container6 .MuiTypography-body1 {
  font-size: 42px; /* Adjust font size */
  color: #ffffff; /* Set text color */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* Other styles for body text appearance */
}

.text-container6 .MuiTypography-h5 span:first-child {
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}


.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 46%;
  height: 0;
  margin-left: 28%;
  box-shadow: 0 10px 18px rgba(52, 87, 220, 0.18);
  border-radius: 16px;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  transform: translateY(0%);
  transition: transform 1s ease-in-out;
  z-index: 1000;
  color: white;
}

.bottom-sheet.closed {
  transition: transform 1s ease-in-out;
  height: 51px;
}

.bottom-sheet.half-open {
    transition: transform 1s ease-in-out;
  height: 100%;
}


.bottom-sheet.semi-half-open {
    transition: transform 1s ease-in-out;
  height: 100%;
}


.bottom-sheet.semi-fully-open {
    transition: transform 1s ease-in-out;
  height: 100%;
}

.bottom-sheet.fully-open {
    transition: transform 1s ease-in-out;
  height: 100%;
}

.uparrow:hover {
  cursor: pointer;
}
.handle {
  height: 20px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
 
  margin: 0;
  padding: 0;
 
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  
  overflow-x: hidden;
}

label {
  color: white;
}

#tema {
  background: orange;
  text-align: left;
  padding: 10px 5px;
  text-align: center;
  position: fixed;
  top: 25px;
  left: 5px;
  font-size: 15px;
  border-radius: 0.5%;
}

header img {
  margin-top: 20px;
  height: 100px;
  width: 100px;
}

#container {
  /* background: #FFF; */
  width: 100%;
  margin: auto;
  padding-right: 0 !important;
  padding: 0px;
  /* border: 1px solid; */
  border-radius: 0.5%;
}

.forms > div {
  padding: 0 90px;
}

header {
  background: #9645c1;
  text-align: center;
  padding: 1%;
  color: white;
}

form {
  margin: 15% auto;
  width: 100%;
}

fieldset {
  border: 0px;
}

input[type="text"],
input[type="date"],
input[type="number"],
#country,
input[type="email"],
input[type="password"],
textarea {
  border: 1px solid #9645c1;
  display: block;
  margin: 15px auto;
  padding: 15px 0;
  border-radius: 5px;
  width: 100%;
}

input[type="radio"] {
  margin-top: 1.5%;
}

#submitButton {
  background: #c1beba;
  padding: 15px 10px;
  float: left;
  cursor: pointer;
  margin-right: 15px;
}

#submitButton:hover {
  filter: brightness(115%);
}

h3 {
  color: white;
  font-size: 45px;
  text-transform: capitalize;
  font-weight: 300;
}

h3 span {
  font-weight: bold;
}

#resetButton {
  background: #e24f2b;
  padding: 15px 10px;
  color: white;
  float: right;
  cursor: pointer;
}

p {
  color: white;
  font-size: 16px;
  line-height: 24px;
}

#resetButton:hover {
  filter: brightness(115%);
}

#part-1 label:after {
  content: " *";
  color: red;
}

#part-2 label:after {
  content: " *";
  color: red;
}

textarea {
  resize: none;
}

/*Media Query */

@media all and (min-width: 0px) and (max-width: 320px) {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  #container {
    width: 90%;
    margin-top: 20%;
  }

  #tema {
    width: 100%;
    left: 0.5px;
    top: 0;
  }

  #resetButton {
    float: left;
  }
}

@media all and (min-width: 321px) and (max-width: 480px) {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  #container {
    width: 90%;
    margin-top: 13%;
  }

  #tema {
    width: 100%;
    left: 0.5px;
    top: 0;
  }

  #resetButton {
    float: left;
  }
}

@media all and (min-width: 481px) and (max-width: 568px) {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  #container {
    width: 90%;
    margin-top: 10%;
  }

  #tema {
    width: 100%;
    left: 0.5px;
    top: 0;
  }
}

@media all and (min-width: 569px) and (max-width: 768px) {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 20px;
  }

  #container {
    width: 90%;
    margin-top: 7%;
  }

  #tema {
    width: 100%;
    left: 0.5px;
    top: 0;
  }
}

ul li {
  color: white;
  padding: 25px 0;
}

h2 {
  color: white;
}

h2 {
  font-weight: 300;
}

h2 span {
  font-weight: bold;
}

button {
  display: block;
  border-radius: 4px;
  background-color: #cd0202;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 17px;
  padding: 16px;
  width: 100%;
  transition: all 0.5s;
  cursor: pointer;
  margin-top: 40px;
}

button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

button span:after {
  content: "»";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -15px;
  transition: 0.5s;
}

button:hover span {
  padding-right: 15px;
}

button:hover span:after {
  opacity: 1;
  right: 0;
}

.forms {
  border-radius: 30px;
  /* margin-top: 155px; */
  margin-bottom: 120px;
  background: #000000d5;
  backdrop-filter: blur(5px);
}

.forms > div {
  display: flex;
  align-items: center;
}

.left-side,
.right-side {
  float: left;
  width: 85%;
  margin-left: 45px;
  margin-top: -55px 

}


.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.container {
  max-width: 1200px;
  /* Adjust the maximum width as needed */
  margin: 0 auto;
  /* Center the container horizontally */
  padding: 20px;
  /* Add padding to the container if desired */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  /* Optional: Add a box shadow */
}

/* media query */
@media only screen and (min-width: 360px) and (max-width: 800px) {
  img {
    max-width: 100%;
    
  }
  
  .logo-c2{
    margin-left: -57px;
    max-width: 100%;
  }
  .sidebar {
    display: none;
  }
  .simple-footer,
  
  .text-container1,
  .text-container3,
  .text-container4,
  .text-container5,
  .text-container6 {
    display: block;
    
  }
  .text-container2 {
    margin-top: 5%; /* Adjust spacing below the navbar */
    margin-bottom: 59px;
    position: relative;
    margin-left: 60px;
    text-align: left; /* Align text to the left */
    align-items: flex-start;
    display: flex;
  
    flex-direction: row;
  
    /* Other styles for container appearance */
  }
  .text-container{
    display: block;
   
   
  }
  .text-image {
    margin-left: -2px;
    height: 169px;
    width: 375px;
  }
  .text-content {
    margin-top: -58px;
    padding-left: 0;
    padding-top: 0;
  }
  .navbar {
    display: flex;
    justify-content: flex-end;
  }
  .logo-1{
    max-width: 100%;
    max-height: 100%;
    margin-left: 24%;
  }
  .text-content1 {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    margin-left: -30px;
    margin-right: 7%;
  }
  .text-content2 {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    padding-left: 0;
    margin-left: 7%;
  }

  .additional-sentence2 {
    padding-right: 0;
  }
  .additional-sentence1 {
    padding-right: 0;
  }
  .text-content2 {
    margin-right: 7%;
    margin-top: 0px;
    
  }
  .text-container{
    margin-right: 7%;
    margin-top: 115px;
  }

  /* p {
    text-align: justify;
  } */

  .text-content3,
  .text-content4,
  .text-content5,
  .text-content6,
  .simple-footer {
    padding-left: 0;
    
    padding-top: 40px;
    margin-left: -22px;
    margin-right: 7%;
  }
  .additional-sentence3,
  .additional-sentence4,
  .additional-sentence5 {
    padding-right: 0;
  }
  .forms > div {
    padding: 35px;
    display: block;
  }
  .left-side,
  .right-side {
    float: left;
    width: 100%;
  }
  #container {
    width: auto;
    padding: 0;
  }
  .simple-footer {
    margin-left: 25px;
    background: none;
    padding: 0 0 30px 0;
  }

  .text-image4,
  .text-image3,.text-image5 {
    margin: auto;
    display: block;
    width: 100px;
    flex-shrink: 0;
    margin-right: 155px ;
    padding-right: 0;
    padding-top: 45px /* margin-bottom: 70%; */;
  }
  .text-image2{
    margin: auto;
    display: none;
    width: 145px;
    flex-shrink: 0;
    margin-right: 133px;
    margin-bottom: 58px;
    padding-right: 0;
    padding-top: 65px /* margin-bottom: 70%; */;
  }
  .logoearth{
  display: none;
  }
  .text-image1 {
    display: block;
    margin: auto;
  }
  .text-image8{
    margin-left: -39px;

  }
  button {
    margin-top: 40px;
    font-size: 15px;
  }
 
  .text-container1 .MuiTypography-body1,
  .text-container3 .MuiTypography-body1,
  .text-container4 .MuiTypography-body1, .text-container5 .MuiTypography-body1, h3 , .text-container6 .MuiTypography-body1{
    
    font-size: 23px;
  }
  .text-container2 .MuiTypography-body1 {
    font-size: 23px;
    color: #000000; 
    margin-left: -50px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    /* Other styles for body text appearance */
  }
  .text-container .MuiTypography-body1{
    margin-top: -10px;
    margin-bottom: 57px;
    font-size: 15px;
    margin-left: 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
.imageearth{
  display: none;
  margin-top: 35px;
  margin-left: -28px;
}
  .h31{
    font-size: 23px;
    margin-top: 10px;
  }
  .text-content > *,
 
  .additional-sentence3 {
    font-size: 14px;
    word-spacing: -2px 
  }
  .additional-sentence6{
    font-size: 14px;
    word-spacing: -1px;
  }
  .additional-sentence5{
    font-size: 14px;
    word-spacing: -2px 
  }
  .additional-sentence4{
    font-size: 14px;
    word-spacing: -2px 
  }
  .additional-sentence2{
    border-left: 1px solid rgb(174 169 169 / 55%);
    border-bottom: 1px inset rgb(231 228 228 / 50%);
    font-size: 14.5px;
    padding: 7px;
    word-spacing: -1px;
    margin-left: -55px;
  }
  .additional-sentence22{
    border-left: 1px solid rgb(174 169 169 / 55%);
    border-bottom: 1px inset rgb(231 228 228 / 50%);
    font-size: 14.5px;
    padding: 4px;
    word-spacing: -1px;
    margin-left: -55px;
  }
  .additional-sentence23{
    border-left: 1px solid rgb(174 169 169 / 55%);
    border-bottom: 1px inset rgb(231 228 228 / 50%);
    font-size: 15px;
    padding: 7px;
    word-spacing: 0px;
    margin-left: -55px;
  }
  .additional-sentence24{
    border-left: 1px solid rgb(174 169 169 / 55%);
    border-bottom: 1px inset rgb(231 228 228 / 50%);
    font-size: 15px;
    padding: 7px;
    word-spacing: 4px;
    margin-left: -55px;
  }

  .additional-sentence1{
    font-size: 16px;
    word-spacing: -1px;
  }

  .text-image3,
  .text-image5 {
    padding-left: 0;
  }
  footer .logo-container{
    margin-left: 0px;
    margin-bottom: 72px;
    justify-content: center;
    margin-top: 45px;
  }
  ul li {
    color: rgb(255, 255, 255);
    padding: 25px 0;
    font-size: 15px;
    line-height: 2em;
}
.footer-nav li {
  margin-right: 0;
}

.footer-nav a {
 
  font-size: 13px;
}
.forms label{
  font-size: 12px;
}
  .button3 {
    width: max-content;
    height: max-content;
    margin-left: auto;
    display: inline-block;
    margin-right: 0;
  }
  .text-content2,
  .text-content4, .text-content5 {
    padding-top: 0;
  }
  .text-content6{
    padding-top: 0;
    margin-right: -42px;
    margin-left: -20px;
  }
  .forms{
    
    margin-top: 0;
    margin-bottom: 45px;
  }
  .left-side, .right-side {
    float: left;
    width: 85%;
    margin-left: 20px;
    margin-top: -95px;
}
.closed-label {
  margin-right: 7px;
  text-align: center;
  padding: 10px;
  background-color: #000000; /* Set a background color */
  color: #fff; /* Set the text color */
  font-size: 18px;
  font-weight: bold;
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
  border-radius: 5px; /* Add rounded corners for a button-like appearance */
  transition: background-color 0.3s ease; /* Add a smooth transition for background color change */
}

.closed-label:hover {
  background-color: #ff0000; /* Change background color on hover for a feedback effect */
}


  .footer-nav ul{
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 23px;
  }
  footer ul li{
    padding: 5px 0;
  }
  .forms p{
    margin-top: 13px;
    font-size: 15px;
  }

  .modal-container {
    position: fixed;
    top: 45%;
    height: 313px;
    width: 313px;
    left: 45%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.636);
  }
  
  .success-message {
    color: #000000;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .success-message-text {
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
    padding-left: 35px;
  }
  .modal-button {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Additional styling for close button */
  .modal-button.close-button {
    background-color: #ccc;
    margin-top: 10px;
  }
  .sucess-img{
    max-width: 35%;
  }
  .sucess-btn{
    font-size: 15px;
    background-color: #ff0000;
    margin-top: 30px;
  }

}
.react-tel-input .country-list .country-name {
  color: #000000;
}

/* MyFormStyles.css */



/* Modal styles */
.modal-container {
  position: fixed;
  top: 45%;
  height: 362px;
  width: 472px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.636);
}

.success-message {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-style: italic;
  font-weight: bold;
  color: #000000;
  font-size: 18px;
  margin-bottom: 20px;
}
.success-message-text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  color: black;
  font-style: italic;
  margin-bottom: 20px;
  padding-left: 0px;
}
.modal-button {
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Additional styling for close button */
.modal-button.close-button {
  background-color: #ccc;
  margin-top: 10px;
}
.sucess-img{
  max-width: 35%;
}
.sucess-btn{
  font-size: 15px;
  background-color: #ff0000;
  margin-top: 30px;
}

@media only screen and (min-width: 360px) and (max-width: 800px) {

  .modal-container {
    position: fixed;
    top: 45%;
    height: 313px;
    width: 313px;
    left: 45%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.636);
  }
  
  .success-message {
    color: #000000;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .success-message-text {
    font-size: 16px;
    color: black;
    margin-bottom: 20px;
    padding-left: 35px;
  }
  .modal-button {
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Additional styling for close button */
  .modal-button.close-button {
    background-color: #ccc;
    margin-top: 10px;
  }
  .sucess-img{
    max-width: 35%;
  }
  .sucess-btn{
    font-size: 15px;
    background-color: #ff0000;
    margin-top: 30px;
  }

  
.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  margin-left: 0%;
  box-shadow: 0 10px 18px rgba(52, 87, 220, 0.18);
  border-radius: 7px;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  transform: translateY(0%);
  transition: transform 1s ease-in-out;
  z-index: 1000;
  color: white;
}

.bottom-sheet.closed {
    transition: transform 1s ease-in-out;
  height: 51px;
}

.bottom-sheet.half-open {
    transition: transform 1s ease-in-out;
  height: 20%;
}


.bottom-sheet.semi-half-open {
    transition: transform 1s ease-in-out;
  height: 100%;
}


.bottom-sheet.semi-fully-open {
    transition: transform 1s ease-in-out;
  height: 75%;
}

.bottom-sheet.fully-open {
    transition: transform 1s ease-in-out;
  height: 100%;
}

.uparrow:hover {
  cursor: pointer;
}
.handle {
  height: 20px;
}
}
.card-container {
  display: flex;
  /* justify-content: space-between;  */
}
.card-container1 {
  display: flex;
  /* justify-content: space-between;  */
}
.card{
  margin-left: 103px;
  margin-top: 162px;
  width: 296px;
  height: 458px;
  background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
  border-radius: 10px;
  margin-bottom: 35px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
 
 .cimage{
  width: 264px;
  margin-top: -44px;
    margin-left: 23px;
    height: 233px;
 }
 .custom-typography{
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #ffffff;
 }
 .carousel-p{
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 5px;
    text-align: left;
    font-size: 15px;
 }


 .card2{
  margin-left: 60px;
  margin-top: 162px;
  width: 296px;
  height: 458px;
  background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
  border-radius: 10px;
  margin-bottom: 35px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
 
 .cimage2{
  width: 264px;
  margin-top: -44px;
    margin-left: 23px;
    height: 233px;
 }
 .custom-typography2{
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #ffffff;
 }
 .carousel-p2{
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 5px;
    text-align: left;
    font-size: 15px;
 }
 .card3{
  margin-left: 60px;
  margin-top: 162px;
  width: 296px;
  height: 458px;
  background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
  border-radius: 10px;
  margin-bottom: 35px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
 
 .cimage3{
  width: 264px;
  margin-top: -44px;
    margin-left: 23px;
    height: 233px;
 }
 .custom-typography3{
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #ffffff;
 }
 .carousel-p3{
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 5px;
    text-align: left;
    font-size: 15px;
 }


 .card4{
  margin-left: 60px;
  margin-top: 162px;
  width: 296px;
  height: 458px;
  background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
  border-radius: 10px;
  margin-bottom: 35px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
 
 .cimage4{
  width: 264px;
  margin-top: -44px;
    margin-left: 23px;
    height: 233px;
 }
 .custom-typography4{
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #ffffff;
 }
 .carousel-p4{
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 5px;
    text-align: left;
    font-size: 15px;
 }

 .logo-1 {
  margin-top: 60px;
  max-width: 100%;
  width: 310px;
  height: 61px;
}

 @media only screen and (min-width: 360px) and (max-width: 800px) {
  .card-container {
    display: none;
    /* justify-content: space-between;  */
  }
  .logo-1{
    margin-left: 2px;
    max-width: 50%;
    height: 35px;
  }
  .card-container1 {
    display: none;
    /* justify-content: space-between;  */
  }
  .card{
    margin-left: 103px;
    margin-top: 162px;
    width: 296px;
    height: 458px;
    background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
    border-radius: 10px;
    margin-bottom: 35px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
   
   .cimage{
    width: 264px;
    margin-top: -44px;
      margin-left: 23px;
      height: 233px;
   }
   .custom-typography{
    margin-bottom: 10px;
    margin-left: 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: #ffffff;
   }
   .carousel-p{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 5px;
      text-align: left;
      font-size: 15px;
   }
  
  
   .card2{
    margin-left: 60px;
    margin-top: 162px;
    width: 296px;
    height: 458px;
    background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
    border-radius: 10px;
    margin-bottom: 35px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
   
   .cimage2{
    width: 264px;
    margin-top: -44px;
      margin-left: 23px;
      height: 233px;
   }
   .custom-typography2{
    margin-bottom: 10px;
    margin-left: 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: #ffffff;
   }
   .carousel-p2{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 5px;
      text-align: left;
      font-size: 15px;
   }
   .card3{
    margin-left: 60px;
    margin-top: 162px;
    width: 296px;
    height: 458px;
    background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
    border-radius: 10px;
    margin-bottom: 35px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
   
   .cimage3{
    width: 264px;
    margin-top: -44px;
      margin-left: 23px;
      height: 233px;
   }
   .custom-typography3{
    margin-bottom: 10px;
    margin-left: 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: #ffffff;
   }
   .carousel-p3{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 5px;
      text-align: left;
      font-size: 15px;
   }
  
  
   .card4{
    margin-left: 60px;
    margin-top: 162px;
    width: 296px;
    height: 458px;
    background: linear-gradient(to right, rgba(183, 28, 28, 1), rgba(156, 18, 18, 1));
    border-radius: 10px;
    margin-bottom: 35px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
   
   .cimage4{
    width: 264px;
    margin-top: -44px;
      margin-left: 23px;
      height: 233px;
   }
   .custom-typography4{
    margin-bottom: 10px;
    margin-left: 5px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: #ffffff;
   }
   .carousel-p4{
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 5px;
      text-align: left;
      font-size: 15px;
   }
  
}
 
.close-button11 {
  display: flex;
  padding: 5px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  margin-top: 1px;
  width: 20% !important;
  justify-content: flex-end;
}

/* @media (max-width: 600px) {
  .close-button11 {
    margin-left: 155px;
    margin-top: 5px;
  }
} */


.custom-bg{
 
  background: linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(58, 2, 2) 97.7%);
  position: fixed;
  bottom: 28px;
  left: 51%;
  transform: translate(-50%);
  text-align: center;
  width: 43%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px 20px;
  z-index: 1000 !important;
  font-weight: bold;
  cursor: pointer;
}

.custom-bg label{
  cursor: pointer;
}

.custom-bg:hover{
  background: linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%);
}

@keyframes custOpacityChange{
  0%{
    opacity: 0;
  }
  50%{
    opacity: .5;
  }
  100%{
    opacity: 1;
  }
}

.cust_model_container{
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 10000 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .5s;
}

.cust_model_form{
  width: 30%;
  height: 95vh;
  background: black;
  padding: 20px 40px 20px 40px;
  border-radius: 10px;

}

.cst_btn{
  margin-top: 5px !important;
}

.cust_model_header{
  margin-left: 46px;
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cust_model_header h4{
  color: white !important;
  font-weight: bold;
  font-size: 1.2rem;
  width: 80%;
  text-align: center;
}

@media (max-width: 800px) {
  .cust_model_form{
    width: 80%;
  }

  .custom-bg{
    background: linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(58, 2, 2) 97.7%);
    position: fixed;
    bottom: 28px;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
    width: 56%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px 20px;
    z-index: 1000 !important;
    font-weight: bold;
    cursor: pointer;
  }
  
  .custom-bg label{
    cursor: pointer;
  }
  
  .custom-bg:hover{
    background: #000000 !important;
  }
  
  @keyframes custOpacityChange{
    0%{
      opacity: 0;
    }
    50%{
      opacity: .5;
    }
    100%{
      opacity: 1;
    }
  }
}

.country-list {
  top: -43px !important;
}

.mobile_err_msg{
  position: absolute;
  font-size: 12px;
  color: red;
  top: -20px;
}
section {
  width: 100%;
  display: inline-block;
  background: #333;
  height: 50vh;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.footer-distributed{
  background: #0000009c;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  border-radius: 20px;
  padding: 56px 73px;
}
.footer-distributed .footer-left{
  width: 30%;
  margin-left: 63px;
    margin-top: 5px;
}
.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
  display: inline-block;
  vertical-align: top;
}






/* The company logo */

.footer-distributed h3{
  color:  #ffffff;
  font: normal 36px 'Open Sans', cursive;
  margin: 0;
}

.footer-distributed h3 span{
  color:  lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links{
  color:  #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a{
  display:inline-block;
  line-height: 1.8;
  font-weight:400;
  text-decoration: none;
  color:  inherit;
}

.footer-distributed .footer-company-name{
  color:  #222;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
  width: 35%;
  margin-bottom: 70px;
}

.footer-distributed .footer-center i{
  background-color:  #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p{
  display: inline-block;
  color: #ffffff;
  font-weight:400;
  vertical-align: middle;
  margin:0;
}

.footer-distributed .footer-center p span{
  display:block;
  font-weight: normal;
  font-size:14px;
  line-height:2;
}

.footer-distributed .footer-center p a{
  color:  rgb(255, 255, 255);
  text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
  width: 7%;
  margin-left: 190px;
}

.footer-distributed .footer-company-about{
  line-height: 20px;
  color:  #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span{
  display: block;
  color:  #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons{
  margin-left: 100px;
    margin-top: 50px;
    
}


.footer-distributed .footer-icons a{
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color:  #33383b;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}
.footer-distributed .footer-icons1{
  margin-left: 100px;
  margin-top: 50px;
  display: none; /* Hide by default */
    
}


.footer-distributed .footer-icons1 a{
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color:  #33383b;
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}



.footer-distributed .footer-icons111{
  
  margin-top: 8px;
  
    
}


.footer-distributed .footer-icons111 a{
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  
  background-image: linear-gradient(326deg, #da0202 0%, #080404 74%);
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 8px;
  margin-bottom: 5px;
}


.footer-distributed .footer-icons112{
  
  margin-top: 8px;
  
    
}


.footer-distributed .footer-icons112 a{
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color:  #ff0000cc;
  background-image: linear-gradient(326deg, #da0202 0%, #080404 74%);
  border-radius: 2px;

  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;

  margin-right: 8px;
  margin-bottom: 5px;
}
/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

  .footer-distributed{
    background: #00000052;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    border-radius: 30px;
    padding: 30px 73px;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right{
    display: block;
    width: 100%;
    margin-bottom: 60px;
   
  }
  .footer-distributed .footer-right{
    width: 37%;
    margin-left: 96px;
  }
  .footer-distributed .footer-icons{
    margin-left: 50px;
      margin-top: -13px;
      display: none;
  }
  .footer-distributed .footer-icons1{
    display: block;
    margin-left: 50px;
    margin-top: 14px;
      
  }
  .footer-distributed .footer-icons111{
  
    margin-top: 8px;
    display: none;
      
  }
  
  
  .footer-distributed .footer-icons111 a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color:  #33383b;
    border-radius: 2px;
  
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
  
    margin-right: 3px;
    margin-bottom: 5px;
  }
  
  
  .footer-distributed .footer-icons112{
    
    margin-top: 8px;
    
      
  }  
  .footer-distributed .footer-icons112 a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color:  #cd3c07;
    border-radius: 2px;
  
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
  
    margin-right: 8px;
    margin-bottom: 5px;
  }

  .footer-distributed .footer-icons114{
    
    margin-top: 8px;
    
      
  }  
  .footer-distributed .footer-icons114 a{
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-image: linear-gradient(326deg, #da0202 0%, #080404 74%);
    border-radius: 2px;
  
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
  
    margin-right: 8px;
    margin-bottom: 5px;
  }
  .footer-distributed .footer-center i{
    margin-left: 0;
  }

}