@charset "UTF-8";
/* *,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 3vmin;
}

html {
  background: #151515;
  color: #fff;
  overflow: hidden;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.slides_container{
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 90%;
  margin: 0;
  padding: 5px;
  font-size: 3vmin;
  left: 6% !important;
  
}

.slides {
  display: grid;
}
.slides > .slide {
  grid-area: 1/-1;
}
.slides > button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: none;
  color: white;
  position: absolute;
  font-size: 5rem;
  width: 5rem;
  height: 5rem;
  top: 30%;
  transition: opacity 0.3s;
  opacity: 0.7;
  z-index: 5;
}
.slides > button:hover {
  opacity: 1;
}
.slides > button:focus {
  outline: none;
}
.slides > button:first-child {
  left: 5%;
}
.slides > button:last-child {
  right: 5%;
}

.slideContent {
  width: 33vw;
  height: 40vw;
  /* height: 150px !important;
  width: 100px !important; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;
  display: grid;
  align-content: center;
  transform-style: preserve-3d;
  transform: perspective(1000px) translateX(calc(100% * var(--offset))) rotateY(calc(-45deg * var(--dir)));
}

.slideContentInner {
  transform-style: preserve-3d;
  transform: translateZ(2rem);
  transition: opacity 0.3s linear;
  text-shadow: 0 0.1rem 1rem #000;
  opacity: 0;
}
.slideContentInner .slideSubtitle,
.slideContentInner .slideTitle {
  font-size: 2rem;
  font-weight: normal;
  letter-spacing: 0.2ch;
  text-transform: uppercase;
  margin: 0;
}
.slideContentInner .slideSubtitle::before {
  content: "— ";
}
.slideContentInner .slideDescription {
  margin: 0;
  font-size: 0.8rem;
  letter-spacing: 0.2ch;
}

.slideBackground {
  position: fixed;
  top: 0;
  left: -10%;
  right: -10%;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s linear, transform 0.3s ease-in-out;
  pointer-events: none;
  transform: translateX(calc(10% * var(--dir)));


}

.slide[data-active] {
  z-index: 2;
  pointer-events: auto;
}
.slide[data-active] .slideBackground {
  opacity: 0.2;
  transform: none;
}
.slide[data-active] .slideContentInner {
  margin-top: 370px;
  margin-left: 23px;
  opacity: 1;
}
.slide[data-active] .slideContent {
  --x: calc(var(--px) - 0.5);
  --y: calc(var(--py) - 0.5);
  opacity: 1;
  transform: perspective(1000px);
}
.slide[data-active] .slideContent:hover {
  transition: none;
  transform: perspective(1000px) rotateY(calc(var(--x) * 45deg)) rotateX(calc(var(--y) * -45deg));
}/*# sourceMappingURL=style.css.map */

@media (max-width: 768px) {
  .slides_container {
    padding-bottom: 43px;
    width: 100%;
    left: 0 !important;
  }

  .slideContent {
    width: 100vw;
    height: 80vh;
  }

  .slideContentInner .slideDescription {
    margin: 16px;
    margin-left: 1px;
    font-size: 0.8rem;
    letter-spacing: 0.2ch;
}
.slides > button:last-child {
  right: -4%;
}
.slides > button:first-child {
  left: -2%;
}
.slide[data-active] .slideContentInner {
  margin-left: 35px;
  opacity: 1;
}

}
