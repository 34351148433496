* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 58px;
  height: 100vh;
  background-color: #000000;
  color: #fff;
}
.sidelogo {
  padding-right: 70px;
  margin-left: -8px;
  width: 30px;
  height: 32px;
}

.sidebar-header {
  padding: 20px;
  text-align: center;
}

.sidebar-header img {
  width: 100px;
}

.sidebar-nav ul {
  list-style-type: none; /* Remove the bullet points from the list */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.sidebar-nav li {
  margin-bottom: 10px; /* Add some spacing between list items (optional) */
}
.icon{
  
  width: 55px !important;
  height: 23px !important;
  margin-bottom: 10px !important;
  cursor: pointer !important;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out !important;
}

.icon:hover {
  transform: scale(1.2) rotate(10deg) translateX(-2px) !important;
  color: #f42d00 !important;

}