/* Hamburger.css */

.hamburger-menu {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
  
  #menu__toggle {
    opacity: 0;
  }
  
  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  
  #menu__toggle:checked ~ .menu__box {
    left: 0 !important;
  }
  
  .menu__btn {
    position: fixed;
    top: 52px;
    left: 20px;
    width: 28px;
    height: 24px;
    cursor: pointer;
    z-index: 1;
  }
  
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #dc0808;
    transition-duration: 0.25s;
  }
  
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
  
  .menu__box {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    width: 76px;
    height: 100%;
    margin: 0;
    padding: 56px 0;
    list-style: none;
    background-color: #101010; /* Set the background color to white */
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transition-duration: 0.25s;
  }
  
  .menu__item {
    display: block;
    padding: 0px 24px;
    color: #ffffff;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: 0.25s;
  }
  
  .menu__item:hover {
    background-color: #ff0000;
  }
  .menuboxlogo{
    margin-top: 35px;
    margin-left: 21px;
  }
  .hamburger-menu.hidden {
    display: none;
  }